import React, { useState, useEffect } from 'react'

import JobOfferCard from './components/JobOfferCard'
import Select from '../../Form/Select'
import { extractSalaryFromBenefits } from './utils/jobUtils'
import { talentPoolId, talentPoolTitle } from './constants'
import { StyledJobOffersSection } from './styled'
import arrowDown from '../../../../static/images/icons/arrow-down.svg'
import SectionHeader from '../../SectionHeader/SectionHeader'
import Button from '../../Buttons'
import useJobOffersQuery from '../../../hooks/graphql/section/useJobOffersQuery'

export const getUniqueSelectOptions = (array, transformationFunction) => {
  const options = [...Array.from(new Set(array.map(transformationFunction).filter(Boolean)))]
  return options.map((value) => ({ value, label: value }))
}

const showJobOffers = ({
  title,
  location,
  id,
  department,
  employment_type,
  benefits,
  internal,
}) => {
  const salary = extractSalaryFromBenefits(benefits)
  return (
    // Talent pool offer is special oofer - when candidate dont fit in any of available offer
    // he can apply to us with this custom form - form is generated using the same logic as regular offer
    (id !== talentPoolId || title !== talentPoolTitle) && (
      <JobOfferCard
        title={title}
        location={location}
        key={id}
        department={department}
        employment_type={employment_type}
        salary={salary}
        opening_soon={internal.content.code === 'soon'}
      />
    )
  )
}

const JobOffers = ({ title, description }) => {
  const jobOffersNotParsed = useJobOffersQuery()

  const [department, setDepartment] = useState('All departments')
  const [jobOffersDisplay, setJobOffersDisplay] = useState([])
  const [jobOffersList, setJobOffersList] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [shouldShowAllJobOffers, setShouldShowAllJobOffers] = useState(false)

  useEffect(() => {
    const preparedJobOffers = prepareJobOffers(jobOffersNotParsed)
    setJobOffersList(preparedJobOffers)
    setJobOffersDisplay(preparedJobOffers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOffersNotParsed])

  useEffect(() => {
    const uniqueDepartments = [
      { value: 'All departments', label: 'All departments' },
      ...getUniqueSelectOptions(jobOffersList, (job) => job.department),
    ]
    setDepartmentOptions(uniqueDepartments)
  }, [jobOffersList])

  useEffect(() => {
    if (department === 'All departments') {
      setJobOffersDisplay(jobOffersList)
      return
    }
    const filteredJobOffers = filterJobOffers(jobOffersList)
    setJobOffersDisplay(filteredJobOffers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, jobOffersList])

  const filterJobOffers = (arr) =>
    arr.filter((job) => {
      // Department names are taken directly from job offers (departmentOptions), so there is no need to lower case them
      if (department && department !== 'All departments' && department !== job.department) {
        return false
      }
      return true
    })

  const prepareJobOffers = (offers) => {
    const jobOffers = offers.map((node) => {
      if (typeof node.internal.content === 'string') {
        node.internal.content = JSON.parse(node.internal.content)
      }
      return node
    })

    const excludedJobOffers = ['Design Ways Conference', 'Talent Pool - application form']

    const jobOffersFiltered = jobOffers.filter((job) => !excludedJobOffers.includes(job.title))

    jobOffersFiltered.sort((a, b) => a.title.localeCompare(b.title))
    return jobOffersFiltered
  }

  const handleSearch = ({ target: { value = '' } }) => {
    const trimmedValue = value.trim()
    if (!trimmedValue) {
      const filteredJobOffers = filterJobOffers(jobOffersList)
      setJobOffersDisplay(filteredJobOffers)
      return
    }
    const foundOffers = jobOffersList.filter((job) => {
      const isMatch = job.title.toLowerCase().includes(trimmedValue.toLowerCase())
      if (department === 'All departments') {
        return isMatch
      }
      return isMatch && job.department === department
    })
    setJobOffersDisplay(foundOffers)
  }

  return (
    <StyledJobOffersSection
      shouldShowAllJobOffers={shouldShowAllJobOffers}
      id="job-offers-section"
      className="job-offers"
    >
      <SectionHeader title={title} subtitle={description} />
      <div className="job-offers__search" id="job-offers__search">
        <div className="job-offers__search-row">
          <div className="job-offers__container">
            <label htmlFor="department" className="job-offers__label">
              Department
            </label>
            <Select
              options={departmentOptions}
              label="Department"
              handleSelect={setDepartment}
              value={department}
            />
          </div>
          <div className="job-offers__container">
            <label htmlFor="search" className="job-offers__label">
              Position
            </label>
            <input
              id="search"
              name="search"
              type="search"
              onChange={handleSearch}
              className="job-offers__search-input"
              placeholder="Start typing"
            />
          </div>
        </div>
      </div>
      <div className="job-offers__wrapper container">
        {shouldShowAllJobOffers
          ? jobOffersDisplay.map(showJobOffers)
          : jobOffersDisplay.slice(0, 2).map(showJobOffers)}
        {jobOffersDisplay.length > 2 && (
          <Button
            onClick={() => setShouldShowAllJobOffers((prev) => !prev)}
            className="job-offers__button"
            variant="bordered"
          >
            {shouldShowAllJobOffers ? 'SEE LESS' : `SEE MORE (${jobOffersDisplay.length - 2})`}
            <img src={arrowDown} alt="" />
          </Button>
        )}
      </div>
    </StyledJobOffersSection>
  )
}

export default JobOffers
