import styled from 'styled-components';

export const SalesAgentContainer = styled.div`
  &:not(:last-child) {
    padding-bottom: 32px;
  }
`;

export const SalesAgentHeading = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 16px;
  font-size: 1rem;
  line-height: 1.25;
  align-items: center;

  h4 {
    font-family: Roboto-Regular, sans-serif;
  }

  .sales-agent__position {
    font-family: Roboto-Regular, sans-serif;
    color: #c3c2d4;
  }
`;

export const SalesAgentMeta = styled.div`
  font-size: 0.813rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 10px;

  --sales-agent-icon-size: 14px;

  a {
    color: #9f9fc0;

    &:hover,
    &:focus {
      color: #00ddaf;
    }
  }

  svg {
    color: #727292;
    width: var(--sales-agent-icon-size);
    height: var(--sales-agent-icon-size);
  }
`;
