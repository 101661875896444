import { v4 as uuidv4 } from 'uuid'
import RyugoAvatar from '../../assets/images/av-ryugo.webp'

const salesAgents = [
  {
    name: 'Ryugo Kimura',
    position: 'Account Executive',
    phone: '+48 507 096 183',
    usPhone: '+1 (888) 341-2098',
    email: 'hello@10clouds.com',
    avatar: RyugoAvatar,
    uuid: uuidv4(),
  },
]

export default salesAgents
