import React from 'react'
import propTypes from 'prop-types'
import { SalesAgentContainer, SalesAgentHeading, SalesAgentMeta } from './styled'

import Image from '../../../../components/Image'
import IconMail from '../../../../components/icons/IconMail'
import IconPhone from '../../../../components/icons/IconPhone'

const SalesAgent = ({ avatar, name, position, phone, usPhone, email, nameAs: NameComponent }) => {
  return (
    <SalesAgentContainer>
      <SalesAgentHeading>
        <Image image={avatar} alt={`${name}'s avatar`} />
        <div>
          <NameComponent>
            {name.split(' ')[0]}&nbsp;{name.split(' ')[1]}
          </NameComponent>
          <span className="sales-agent__position">
            {position.split(' ')[0]}&nbsp;{position.split(' ')[1]}
          </span>
        </div>
      </SalesAgentHeading>
      <SalesAgentMeta>
        <IconPhone />
        <a href={`tel:${phone}`}>{phone}</a>
      </SalesAgentMeta>
      {/* <SalesAgentMeta>
        <IconPhone />
        <a href={`tel:${usPhone.replace(/[^\d+]/g, '')}`}>{usPhone}</a>
      </SalesAgentMeta> */}
      <SalesAgentMeta>
        <IconMail />
        <a href={`mailto:${email}`}>{email}</a>
      </SalesAgentMeta>
    </SalesAgentContainer>
  )
}

SalesAgent.defaultProps = {
  nameAs: 'span',
}

SalesAgent.propTypes = {
  avatar: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  position: propTypes.string.isRequired,
  phone: propTypes.string.isRequired,
  email: propTypes.string.isRequired,
  nameAs: propTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'div']),
}

export default SalesAgent
