import React from 'react'
import styled from 'styled-components'
import slugify from 'slugify'
import { IconLink, NavLink } from '../../../Link'
import { colors, breakpoints } from '../../../../../constants/style'
import { useCareerDictionaryQuery } from 'hooks/graphql/section'
import IconArrow from '../../../icons/IconArrow'

const StyledJobOfferCard = styled((props) => <NavLink {...props} />)`
  background-color: #ffffff;
  width: 100%;
  padding: 3rem 2rem;
  text-transform: unset;
  border-top: none;
  border-bottom: 1px solid #d4d4e1;
  border-left: 1px solid #d4d4e1;
  border-right: 1px solid #d4d4e1;

  font-family: 'BioSans-Regular', sans-serif;

  & > span {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:last-child {
    border-bottom: 1px solid #d4d4e1;
  }

  &:first-child {
    border-top: 1px solid #d4d4e1;
  }

  .job-offer__column-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .job-offer__column-right {
    display: flex;
    align-items: center;
  }

  .job-offer__title {
    font-size: 1.75rem;
    color: ${colors.titleDark};
    font-family: 'BioSans-Regular', sans-serif;
    margin-bottom: 1.5rem;
    text-transform: none;
  }

  .job-offer__opening-soon {
    font-size: 1rem;
    background: linear-gradient(90deg, #8658eb, #6831e4);
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.75rem;
  }

  .job-offer__salary {
    font-family: 'BioSans-Regular', sans-serif;
    font-size: 1rem;
    color: ${colors.primary};
  }

  .job-offer__location,
  .job-offer__employment-type {
    font-family: Roboto-Regular, sans-serif;
    color: #4e4d6c;
    font-size: 1rem;
  }

  .job-offer__button {
    margin-left: 2rem;

    .link__icon {
      margin: 0;
      transition: background-color 0.25s ease-in-out;
      svg {
        transition: fill 0.25s ease-in-out;
      }
    }

    &:hover {
      color: ${colors.titleDark};
      text-decoration: underline;

      .link__icon {
        background-color: ${colors.primary};

        svg {
          fill: #ffffff;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .job-offer__button {
      display: none;
    }
    .job-offer__salary {
      max-width: 12.75rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .job-offer__salary {
        margin-top: 2rem;
      }
    }
  }
`

const JobOfferCard = ({ title, location, employment_type, salary, opening_soon }) => {
  const { remoteLabel, remoteOnlyLabel } = useCareerDictionaryQuery()

  const showEmploymentType = false

  const getLocationLabel = ({ city, telecommuting, country }) => {
    if (
      country !== 'Poland' &&
      country !== undefined &&
      (telecommuting || (!telecommuting && !city))
    ) {
      return `Remote (Europe)`
    }
    if (country !== 'Poland' && country !== undefined) return country

    if (!city && telecommuting) return remoteOnlyLabel

    if (city && !telecommuting) return city

    if (city && telecommuting) return remoteOnlyLabel

    return remoteLabel
  }
  const locationLabel = getLocationLabel(location)

  const jobOfferLink = `/careers/${slugify(title, { lower: true })}-${slugify(location.country, {
    lower: true,
  })}/`
  return (
    <StyledJobOfferCard to={jobOfferLink}>
      <div className="job-offer__column-left">
        {opening_soon && <div className="job-offer__opening-soon">OPENING SOON</div>}
        <h2 className="job-offer__title">{title}</h2>
        <div>
          {showEmploymentType && (
            <span className="job-offer__employment-type">{`${employment_type}, `}</span>
          )}
          <span className="job-offer__location">{locationLabel}</span>
        </div>
      </div>
      <div className="job-offer__column-right">
        <p className="job-offer__salary">{salary}</p>
        <IconLink className="job-offer__button" isNested to={jobOfferLink} icon={<IconArrow />} />
      </div>
    </StyledJobOfferCard>
  )
}

export default JobOfferCard
